@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800;900&display=swap");

html,
body {
	height: 100%;
	scroll-behavior: smooth;
}

body {
	margin: 0;
	padding: 0;
	font-size: 14px;
	width: 100%;
	background-color: #f7f9fa;
	overflow-x: hidden;
	font-weight: 400;
	font-family: "Montserrat", sans-serif;
}

ul {
	list-style: none;
	padding: 0;
	margin: 0;
}

a,
a:hover {
	text-decoration: none;
	color: #5d5f5e;
}

:focus {
	outline: none;
}

button:focus {
	outline: none;
}

* {
	scrollbar-width: thin;
	scrollbar-color: #f1f1f1;
}

::-webkit-scrollbar {
	width: 5px;
	height: 5px;
	border-radius: 6px;
}

::-webkit-scrollbar-track {
	background: #f1f1f1;
	border-radius: 6px;
}

::-webkit-scrollbar-thumb {
	background: #c1c1c1;
	border-radius: 6px;
}

::-webkit-input-placeholder {
	color: #6c6a81;
	font-weight: 400;
}

::-moz-placeholder {
	color: #6c6a81;
	font-weight: 400;
}

:-ms-input-placeholder {
	color: #6c6a81;
	font-weight: 400;
}

:-moz-placeholder {
	color: #6c6a81;
	font-weight: 400;
}

::-webkit-scrollbar-thumb:hover {
	background: #a8a8a8;
	border-radius: 6px;
}

.error-span {
	color: #ff0000;
}

.login-side-img img {
	height: 100vh;
	object-fit: cover;
	width: 100%;
}

.login-main-part-scroll {
	height: 100vh;
	overflow-x: hidden;
	overflow-y: auto;
	padding: 15px 0;
}

.login-main-side {
	display: flex;
	flex-direction: column;
	min-height: 100%;
}

.white-bx-info {
	background-color: #ffffff;
	border: 1px solid #ededf2;
	box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.06);
	border-radius: 10px;
	padding: 15px;
	word-break: break-word;
}

.login-main-box {
	max-width: 500px;
	min-width: 500px;
	padding: 35px;
}

.form-lbl-class {
	display: block;
	font-weight: 500;
	font-size: 14px;
	color: #040d21;
}

.style-input-class {
	border: 1px solid #a3aed04d;
	border-radius: 10px;
	height: 50px;
	font-weight: 500;
	font-size: 14px;
	color: #040d21;
}

.style-input-class:focus {
	box-shadow: 0 0 1px #7c1dc94d;
	border-color: #7c1dc9;
}

.btn-smart-comn {
	color: #fff;
	border: 0;
	outline: none;
	padding: 10px 20px;
	font-weight: 500;
	font-size: 15px;
	text-transform: capitalize;
	display: inline-block;
	background: linear-gradient(135.73deg, #c22277 -8.36%, #770edf 51.56%);
	border-radius: 8px;
	cursor: pointer;
	line-height: 26px;
}

.login-text-top h1 {
	font-weight: 700;
	font-size: 18px;
	color: #040d21;
}

.login-text-top p {
	font-size: 14px;
	color: #6c6a81;
}

.eye-pwd {
	align-items: center;
	bottom: 0;
	color: #9ca3af;
	cursor: pointer;
	display: inline-flex;
	font-size: 20px;
	font-weight: 700;
	margin-bottom: auto;
	margin-top: auto;
	position: absolute;
	right: 15px;
	top: 0;
}

.form-link-style {
	font-weight: 600;
	font-size: 14px;
	color: #7c1dc9;
	cursor: pointer;
}

.custom-lbl-part {
	cursor: pointer;
	display: inline-block;
	color: #232222;
	font-size: 14px;
	font-weight: 500;
	padding-left: 30px;
	position: relative;
	user-select: none;
}

.custom-lbl-part input {
	cursor: pointer;
	height: 0;
	opacity: 0;
	position: absolute;
	width: 0;
}

.custom-checkbox-class {
	background: #fff;
	border: 1px solid rgba(163, 174, 208, 0.3019607843);
	border-radius: 3px;
	height: 20px;
	width: 20px;
	left: 0;
	margin-bottom: 0 !important;
	padding: 0;
	position: absolute;
	top: 0;
}

.custom-lbl-part .custom-checkbox-class:after {
	border: solid #fff;
	border-width: 0 2px 2px 0;
	height: 11px;
	left: 6px;
	top: 2px;
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg);
	width: 6px;
}

.custom-checkbox-class:after {
	position: absolute;
	content: "";
}

.custom-lbl-part input:checked ~ .custom-checkbox-class {
	background-color: #7c1dc9;
	border-color: #7c1dc9;
}

.custom-lbl-part input:checked ~ .custom-checkbox-class::after {
	display: block;
}

.eye-pwd i {
	color: #6c6a81;
}

.otp-group input {
	border: 1px solid rgba(163, 174, 208, 0.3019607843) !important;
}

.otp-group input:focus {
	border: 1px solid #7c1dc9 !important;
}

.dash-wrapper {
	height: auto;
	min-height: 100%;
	position: relative;
	top: 0;
	width: 100%;
}

.header-fix-top-section {
	align-items: center;
	background-color: #ffffff;
	display: flex;
	height: 75px;
	left: 0;
	margin-left: auto;
	padding: 7px 15px;
	position: fixed;
	right: 0;
	top: 0;
	transition: all 0.3s;
	width: calc(100% - 250px);
	z-index: 97;
}

.main-title-dash h1 {
	font-weight: 600;
	font-size: 17px;
	color: #6c6a81;
	text-transform: capitalize;
	margin-bottom: 0;
}

.count-number-top {
	background-color: #ea5455;
	box-shadow: 0px 2px 4px rgb(0 0 0 / 5%);
	width: 16px;
	height: 16px;
	max-width: 16px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	border-radius: 50%;
	color: #fff !important;
	text-transform: capitalize;
	text-align: center;
	line-height: 16px;
	font-size: 11px !important;
	position: absolute;
	top: -9px;
	right: -9px;
}

.prf-hdr-top span {
	font-weight: 600;
	font-size: 14px;
	color: #040d21;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.prf-hdr-top bdi {
	font-size: 13px;
	color: #6c6a81;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.prf-hdr-top img {
	border-radius: 50%;
	object-fit: cover;
	width: 38px;
	height: 38px;
	min-width: 38px;
	background-color: #b9b3f7;
}

.sidebar-main-section {
	background-color: #fff;
	border-right: 1px solid #edf2f7;
	height: 100%;
	left: 0;
	position: fixed;
	top: 0;
	transition: all 0.3s;
	width: 250px;
	z-index: 9;
}

.sidebar-menu-boxes li {
	margin: 10px 0;
}

.sidebar-menu-boxes li a {
	align-items: center;
	cursor: pointer;
	display: flex;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #6c6a81;
	padding: 10px 15px;
	text-transform: capitalize;
	transition: all 0.3s;
	white-space: nowrap;
	border-left: 2px solid transparent;
}

.sidebar-menu-boxes li a svg {
	height: 20px;
	width: 20px;
	min-width: 20px;
	margin-right: 10px;
}

.sidebar-menu-boxes li a.active {
	background: linear-gradient(135.73deg, #c22277 -8.36%, #770edf 51.56%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	text-fill-color: transparent;
	border-color: #7c1dc9;
}

.sidebar-menu-boxes li a.active svg path {
	fill: #7c1dc9;
}

.sidebar-menu-boxes {
	height: calc(100vh - 135px);
	overflow-x: hidden;
	overflow-y: auto;
}

.sidebar-log-fix {
	margin: 10px 0;
}

.sidebar-log-fix button {
	align-items: center;
	cursor: pointer;
	display: flex;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #6c6a81;
	padding: 10px 15px;
	text-transform: capitalize;
	transition: all 0.3s;
	white-space: nowrap;
	border-left: 2px solid transparent;
	width: 100%;
}

.sidebar-log-fix button svg {
	height: 20px;
	width: 20px;
	margin-right: 10px;
}

.brand-title {
	height: 75px;
	padding: 15px;
	position: relative;
	width: 250px;
	justify-content: center;
}

.brand-title img {
	max-width: 36px;
	cursor: pointer;
}

.brand-title span {
	font-weight: 800;
	font-size: 18px;
	color: #000000;
	padding-left: 10px;
}

.content-main-section {
	backface-visibility: hidden;
	margin-left: 250px;
	position: relative;
	padding: 75px 0 15px 0;
	transition: all 0.3s ease, background 0s;
}

.comn-inr-title {
	padding: 20px 0;
}

.comn-inr-title h2 {
	margin-bottom: 0;
	font-weight: 600;
	font-size: 16px;
	color: #6c6a81;
}

.close-arrow-hide {
	display: none;
}

.modal-comn-info .modal-header {
	border: 0;
	border-radius: 0;
	flex-direction: column-reverse;
}

.modal-comn-info .modal-title {
	font-weight: 700;
	font-size: 18px;
	color: #040d21;
}

.modal-comn-info .modal-content {
	background-color: #ffffff;
	border-radius: 10px;
	border: 0;
	padding: 10px;
}

.modal-comn-info .modal-header .btn-close {
	opacity: 1;
	box-shadow: none;
}

.loader-dateset-main {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #000000b3;
	z-index: 9999;
}

.loader-dateset-main svg {
	position: relative;
	z-index: 9;
	max-width: 200px;
}

.available-img {
	width: 450px;
	height: 300px;
	border: 1px solid #232222;
	margin-bottom: 20px;
}

.available-img img {
	width: 400px;
	height: 250px;
}

//------------------- media query -------------------

@media (min-width: 1200px) {
	.dash-main-class-add .sidebar-main-section {
		width: 80px;
	}

	.dash-main-class-add .brand-title {
		width: 80px;
	}

	.dash-main-class-add .brand-title span {
		display: none;
	}

	.dash-main-class-add .sidebar-menu-boxes {
		overflow: visible;
	}

	.dash-main-class-add .sidebar-menu-boxes li a {
		text-overflow: inherit;
		justify-content: center;
	}

	.dash-main-class-add .sidebar-menu-boxes li a span {
		opacity: 0;
		visibility: hidden;
		width: 0;
		height: 0;
	}

	.dash-main-class-add .sidebar-menu-boxes li a svg {
		margin-right: 0;
	}

	.dash-main-class-add .sidebar-log-fix button {
		text-overflow: inherit;
		justify-content: center;
	}

	.dash-main-class-add .sidebar-log-fix button span {
		opacity: 0;
		visibility: hidden;
		width: 0;
		height: 0;
	}

	.dash-main-class-add .sidebar-log-fix button svg {
		text-overflow: inherit;
		justify-content: center;
		margin-right: 0;
	}

	.dash-main-class-add .header-fix-top-section {
		width: calc(100% - 80px);
	}

	.dash-main-class-add .close-arrow-show {
		display: none;
	}

	.dash-main-class-add .close-arrow-hide {
		display: inline-block;
	}

	.dash-main-class-add .content-main-section {
		margin-left: 80px;
	}
}

@media (max-width: 1199px) {
	.content-main-section {
		margin-left: 0;
	}

	.header-fix-top-section {
		width: 100%;
	}

	.sidebar-main-section {
		height: 100%;
		left: -290px;
		position: fixed;
		top: 0;
		transition: all 0.5s ease-in-out;
		width: 290px;
		z-index: 100;
	}

	.dash-main-class-add .sidebar-main-section {
		left: 0;
	}

	.dash-main-class-add .overlay {
		background-color: rgba(0, 0, 0, 0.7);
		bottom: 0;
		left: 0;
		position: fixed;
		right: 0;
		top: 0;
		z-index: 99;
		transition: all 0.5s ease-in-out;
	}

	.brand-title {
		width: 290px;
	}
}

@media (max-width: 1024px) {
	::-webkit-scrollbar {
		width: 0;
		height: 0;
	}
}

@media (max-width: 767px) {
	.login-main-box {
		max-width: 100%;
		min-width: 100%;
		padding: 15px;
	}

	.comn-inr-title {
		padding: 15px 0;
	}

	.main-title-dash h1 {
		font-size: 16px;
	}

	.comn-inr-title h2 {
		font-size: 15px;
	}

	.btn-smart-comn {
		outline: none;
		padding: 8px 15px;
		font-size: 14px;
	}
}
